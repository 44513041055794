<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-volume', params: { accountId: this.$route.params.accountId } }">Volumes</router-link>
                    <!-- <router-link :to="{ name: 'account-edit-volume', params: { accountId: this.$route.params.accountId, volumeId: this.$route.params.volumeId } }">{{ volume.label }}</router-link> &gt; -->
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ volume.label }}</h1>
                <p class="text-caption text-center">Volume access control</p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="volume">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0 mt-8">Label</p>
                    <p class="mb-0 pb-0">
                        {{ volume.label }}
                    </p>

                    <p class="text-overline mb-0 mt-8">Volume ID</p>
                    <p class="mb-0 pb-0">
                        {{ volume.id }}
                    </p>

                    <p class="text-overline mb-0 mt-8">
                        Authorized users
                        <v-btn icon color="purple" @click="linkUserVolumeDialog = true">
                            <font-awesome-icon :icon="['fas', 'plus']"/>
                        </v-btn>
                    </p>
                    <p class="mb-0 pb-0" v-if="!linkUserVolumeList">
                        No authorized users.
                    </p>
                    <v-simple-table v-if="linkUserVolumeList">
                        <template #default>
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Read</th>
                                    <th>Write</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(linkUserVolume, idx) in linkUserVolumeList" :key="idx">
                                    <td>{{ linkUserVolume.label }}</td>
                                    <td>
                                        <v-checkbox :input-value="linkUserVolume.permit_read" @change="editLinkUserVolumePermitRead(linkUserVolume, idx)" :disabled="!isAdmin"/>
                                        <!-- <template v-if="linkUserVolume.permit_read">
                                            <font-awesome-icon :icon="['fas', 'check']" color="green"/>
                                        </template> -->
                                        <!-- <template v-if="!linkUserVolume.permit_read">
                                            <font-awesome-icon :icon="['fas', 'check']" color="red"/>
                                        </template> -->
                                        <!-- <template v-if="isPermitServiceAdmin || isPermitAccountAdmin">
                                            <font-awesome-icon :icon="['fas', 'pencil-alt']" color="black"/>
                                        </template> -->
                                    </td>
                                    <td>
                                        <v-checkbox :input-value="linkUserVolume.permit_write" @change="editLinkUserVolumePermitWrite(linkUserVolume, idx)" :disabled="!isAdmin"/>
                                        <!-- <template v-if="linkUserVolume.permit_write">
                                            <font-awesome-icon :icon="['fas', 'check']" color="green"/>
                                        </template> -->
                                        <!-- <template v-if="!linkUserVolume.permit_write">
                                            <font-awesome-icon :icon="['fas', 'check']" color="red"/>
                                        </template> -->
                                        <!-- <template v-if="isPermitServiceAdmin || isPermitAccountAdmin">
                                            <font-awesome-icon :icon="['fas', 'pencil-alt']" color="black"/>
                                        </template> -->
                                    </td>
                                </tr>
                                <!-- <CheckTableRow item="Username assigned" :status="isUsernameAssigned" yesno :comment="user.ssh_alias"/>
                                <CheckTableRow item="UID assigned" :status="isUsernameCreated" yesno :progress="isCheckInProgress" :comment="status.uid"/>
                                <CheckTableRow item="Password" :status="isPasswordEnabled" abled :progress="isCheckInProgress"/>
                                <CheckTableRow item="Public Key" :status="isPublicKeyEnabled" abled :progress="isCheckInProgress"/> -->
                                <!-- TODO: button to open dialog and show the public key (remember, admin might use this to look at a user's ssh status also, and the admin doesn't go to the user's profile page, so this is the only place to see it) -->
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-dialog v-model="linkUserVolumeDialog" max-width="600">
                        <v-card tile elevation="4" class="pa-0" max-width="600">
                            <v-toolbar short flat color="white">
                                <v-toolbar-title class="purple--text">Add authorized user</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="px-5" v-if="linkAccountUserList">
                                <!-- todo: v-select with user to add; or combo box so you can type in user name or email address and it will find them using search api -->
                                <v-select :items="linkAccountUserList" item-text="label" item-value="user_id" outlined v-model="linkUserVolumeSelectedUserId"></v-select>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="purple" @click="createLinkUserVolume">
                                    <span>Add</span>
                                </v-btn>
                                <v-btn text color="grey" @click="linkUserVolumeDialog = false">
                                    <span>Close</span>
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="purple--text"/></p>
                    <p class="mb-0 pb-0">
                        Check current access control list on volume.
                    </p>
                    <p class="mb-0 pb-0">
                        <v-btn color="purple" class="white--text" @click="checkVolumeAccess">Check</v-btn>
                    </p>
                    </template>

                    <template v-ifdev>
                    <v-expansion-panels class="mt-8">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>User list <font-awesome-icon :icon="['far', 'code']" class="grey--text"/></span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(linkUserVolumeList, null, 2 ) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    </template>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
/* eslint-disable no-await-in-loop */
import { mapState } from 'vuex';

export default {
    data: () => ({
        account: null,
        volume: null,
        linkAccountUserList: null,
        linkUserVolumeList: null,
        linkUserVolumeDialog: false,
        linkUserVolumeSelectedUserId: null,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.volume !== null;
        },
        isPermitAccountAdmin() {
            // return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
            // TODO: need to have user's role from account... we need find the CURRENT user's role in linkAccountUserList
            return false;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isAdmin() {
            return this.isPermitAccountAdmin || this.isPermitServiceAdmin;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadVolume() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadVolume: true });
                const response = await this.$client.account(this.$route.params.accountId).volume.get(this.$route.params.volumeId);
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.volume = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load volume');
                }
            } catch (err) {
                console.error('failed to load volume', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadVolume: false });
            }
        },
        async loadLinkAccountUserList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadLinkAccountUserList: true });
                const response = await this.$client.account(this.$route.params.accountId).linkAccountUser.search();
                console.log(`loadLinkAccountUserList: response ${JSON.stringify(response)}`);
                if (response?.list) {
                    this.linkAccountUserList = response.list.map((item) => {
                        const copy = { ...item };
                        copy.label = `${item.user_display_name} (${item.user_email})`;
                        return copy;
                    });
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load LinkAccountUserList', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadLinkAccountUserList: false });
            }
        },
        async loadLinkUserVolumeList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadLinkUserVolumeList: true });
                const response = await this.$client.account(this.$route.params.accountId).linkUserVolume.search({ volume_id: this.$route.params.volumeId });
                console.log(`loadLinkUserVolumeList: response ${JSON.stringify(response)}`);
                if (response?.list) {
                    this.linkUserVolumeList = response.list.map((item) => {
                        const copy = { ...item };
                        copy.label = `${item.user_display_name} (${item.user_email})`;
                        copy.permit_read = item.permit?.mount?.includes('read');
                        copy.permit_write = item.permit?.mount?.includes('write');
                        return copy;
                    });
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load LinkUserVolumeList', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadLinkUserVolumeList: false });
            }
        },
        async createLinkUserVolume() {
            try {
                this.$store.commit('loading', { createLinkUserVolume: true });
                const response = await this.$client.account(this.$route.params.accountId).linkUserVolume.create({ user_id: this.linkUserVolumeSelectedUserId, volume_id: this.$route.params.volumeId });
                console.log(`createLinkUserVolume: response ${JSON.stringify(response)}`);
                if (response?.isLinked) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Added authorized user' });
                    this.loadLinkUserVolumeList();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to add authorized user' });
                }
            } catch (err) {
                console.error('failed to add authorized user', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to add authorized user' });
            } finally {
                this.$store.commit('loading', { createLinkUserVolume: false });
                this.linkUserVolumeDialog = false;
            }
        },
        async editLinkUserVolumePermitRead(linkUserVolume, idx) {
            try {
                this.$store.commit('loading', { editLinkUserVolumePermitRead: true });
                console.log(`editLinkUserVolumePermitRead: ${linkUserVolume.user_id} idx ${idx}`);
                const currentValue = this.linkUserVolumeList[idx].permit_read;
                const request = { permit_read: !currentValue };
                const response = await this.$client.account(this.$route.params.accountId).linkUserVolume.edit({ user_id: linkUserVolume.user_id, volume_id: linkUserVolume.volume_id }, request);
                console.log(`editLinkUserVolumePermitRead: response ${JSON.stringify(response)}`);
                if (response.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Saved changes' });
                    this.linkUserVolumeList.splice(idx, 1, { ...this.linkUserVolumeList[idx], permit_read: request.permit_read });
                }
            } catch (err) {
                console.error('editLinkUserVolumePermitRead to edit permission', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit read permission' });
            } finally {
                this.$store.commit('loading', { editLinkUserVolumePermitRead: false });
            }
        },
        async editLinkUserVolumePermitWrite(linkUserVolume, idx) {
            try {
                this.$store.commit('loading', { editLinkUserVolumePermitWrite: true });
                console.log(`editLinkUserVolumePermitWrite: ${linkUserVolume.user_id} idx ${idx}`);
                const currentValue = this.linkUserVolumeList[idx].permit_write;
                const request = { permit_write: !currentValue };
                const response = await this.$client.account(this.$route.params.accountId).linkUserVolume.edit({ user_id: linkUserVolume.user_id, volume_id: linkUserVolume.volume_id }, request);
                console.log(`editLinkUserVolumePermitWrite: response ${JSON.stringify(response)}`);
                if (response.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Saved changes' });
                    this.linkUserVolumeList.splice(idx, 1, { ...this.linkUserVolumeList[idx], permit_write: request.permit_write });
                }
            } catch (err) {
                console.error('editLinkUserVolumePermitWrite to edit permission', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit write permission' });
            } finally {
                this.$store.commit('loading', { editLinkUserVolumePermitWrite: false });
            }
        },
        async checkVolumeAccess() {
            try {
                this.error = false;
                this.$store.commit('loading', { checkVolumeAccess: true });
                const checkResponse = await this.$client.account(this.$route.params.accountId).volume.check(this.$route.params.volumeId, { item: 'access' });
                console.log(`checkVolumeAccess: checkResponse ${JSON.stringify(checkResponse)}`);
                if (Array.isArray(checkResponse?.fixAllow)) {
                    // TODO: this can be done in parallel, with multiple user fixes happening concurrently
                    for (let i = 0; i < checkResponse.fixAllow.length; i += 1) {
                        // each item is a user id
                        console.log(`checkVolumeAccess: fixing ${i + 1} of ${checkResponse.fixAllow.length}`);
                        // look up the user's intended permit
                        const linkUserVolume = this.linkUserVolumeList.find((item) => item.user_id === checkResponse.fixAllow[i]);
                        // send a request to fix it
                        const request = { permit_read: linkUserVolume.permit_read, permit_write: linkUserVolume.permit_write, fix: true };
                        const fixResponse = await this.$client.account(this.$route.params.accountId).linkUserVolume.edit({ user_id: linkUserVolume.user_id, volume_id: linkUserVolume.volume_id }, request);
                        console.log(`editLinkUserVolumePermitRead: fixResponse ${JSON.stringify(fixResponse)}`);
                        if (fixResponse.isEdited) {
                            this.$bus.$emit('snackbar', { type: 'success', headline: 'Fixed access for user', message: linkUserVolume.user_display_name });
                            const idx = this.linkUserVolumeList.findIndex((item) => item.user_id === checkResponse.fixAllow[i]);
                            this.linkUserVolumeList.splice(idx, 1, { ...this.linkUserVolumeList[idx], ...request });
                        }
                    }
                }
                if (Array.isArray(checkResponse?.fixDeny)) {
                    // TODO: this can be done in parallel, with multiple user fixes happening concurrently
                    for (let i = 0; i < checkResponse.fixDeny.length; i += 1) {
                        // each item is a user id that should NOT be in the actual acl at all, because they don't have a link-user-volume record
                        console.log(`checkVolumeAccess: fixing ${i + 1} of ${checkResponse.fixDeny.length}`);
                        const fixResponse = await this.$client.account(this.$route.params.accountId).linkUserVolume.delete({ user_id: checkResponse.fixDeny[i], volume_id: this.$route.params.volumeId, fix: true });
                        if (fixResponse.isDeleted) {
                            this.$bus.$emit('snackbar', { type: 'success', headline: 'Fixed access for user', message: checkResponse.fixDeny[i] });
                            const idx = this.linkUserVolumeList.findIndex((item) => item.user_id === checkResponse.fixDeny[i]);
                            if (idx > -1) {
                                this.linkUserVolumeList.splice(idx, 1);
                            }
                        }
                    }
                }
                const count = checkResponse?.fixAllow?.length + checkResponse?.fixDeny?.length;
                if (count === 0) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'No issues were found' });
                }
            } catch (err) {
                console.error('failed to check volume access', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to check volume access' });
            } finally {
                this.$store.commit('loading', { checkVolumeAccess: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadVolume();
        this.loadLinkUserVolumeList();
        this.loadLinkAccountUserList();
    },
};
</script>
